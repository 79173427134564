import React, {useState, useEffect, useContext, memo} from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import AuthContext from '../../../middlewares/AuthContext';
import Swal from 'sweetalert2';
import utilities from '../../../utilities/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTableList, faWarning, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import 'chart.js/auto';
import { Chart as ChartJs } from "chart.js";
import { Chart, Bar, Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJs.register(ChartDataLabels);

/***************************************************************************************************************************/
const TdPrices = memo(({ product, partner }) =>  { 

    if(partner === null || product === null){
        return <td>-</td>
    }

    const dsc = (partner.id === 1 && product.id === 2) ? partner.discount_smp : partner.discount;

    let real_price = product.list_price;
    let currency   = 'usd'
    if(partner.country.toLowerCase() === 'mexico' ||
    partner.country.toLowerCase() === 'méxico'){
        real_price = product.list_price_mx;
        currency   = 'mxn'
    }
    const discount    = real_price * (dsc / 100);
    const final_price = real_price - discount;

    const formatted_price = (final_price * 100) / 100; 

    return (
        <td>
            {
                partner !== null
                ?   <>
                        <span>{dsc}%</span>
                            <b> /// </b>
                        <span>
                            = {"$"+ formatted_price.toFixed(2)+` ${currency}`}
                        </span>
                    </>
                : '-'
            }
        </td>
     );
}, (prevProps, nextProps) => {
    return prevProps.product === nextProps.product;
});
const AdminDashboard = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [dashboardOne, setDashboardOne] = useState(null);
    const [today, settoday]               = useState(DateTime.now({zone: 'utc'}));
    const [data, setdata]                 = useState(null);
    //
    const [dataSum, setDataSum]                           = useState(null);
    const [dataAverage, setDataAverage]                   = useState(null);
    const [dataAverageByProduct, setDataAverageByProduct] = useState(null);
    const [dataAverageByUsers, setDataAverageByUsers]     = useState(null);
    //
    const [loading, setloading]           = useState(false);
    const [products, setProducts]         = useState([]);
    const [partner, setPartner]           = useState(null);
    const { t, i18n }                     = useTranslation();

/***************************************************************************************************************************/

    const getAverage = async() => { 
        const headers = await getHeaders()
            await axios.get(`${url}dashboards/average`, {headers})
                        .then(res => {
                            const labels         = res.data.message
                            const average        = res.data.message2
                            const averageByP     = res.data.message3
                            const averageByUsers = res.data.message4

                            setDataSum({
                                labels: labels.map(e => e.label),
                                datasets: [
                                    {
                                    label: 'Pro',
                                    data: labels.map((e) => e.isPro),
                                    backgroundColor: 'rgba(25, 135, 84, 0.8)',
                                  },
                                  {
                                    label: 'Free',
                                    data: labels.map((e) => e.noPro),
                                    backgroundColor: 'rgba(220, 53, 69, 0.7)',
                                  },]
                            });
                            
                            console.log('average')
                            setDataAverage(average);                           
                            setDataAverageByProduct(averageByP);
                            setDataAverageByUsers(averageByUsers);
                        }).catch(err => {
                            Swal.fire({
                                icon: 'error',
                                title: err?.response?.data?.response || err
                            });
                        });
    }

    useEffect(() => {
        
        (async () =>{
            const headers = await getHeaders()
            await axios.get(`${url}dashboards`, {headers})
                        .then(res => {
                            setDashboardOne(res.data.message)
                            const y = res.data.dashboards[0].map(e => e.activated);
                            const x = res.data.dashboards[0].map(e => DateTime.fromISO(e.createdAt).toLocaleString({day: '2-digit'}) );
                            console.log(x)
                            console.log(y)
                            // console.log(res.data.dates)
                            setdata({
                                labels: res.data.dashboards[0].map(e => DateTime.fromISO(e.createdAt).toLocaleString({day: '2-digit'}) ),
                                datasets: [
                                {
                                    // label: 'Activated',
                                    label: t('dashboard.activated'),
                                    data: res.data.dashboards[0].map(e => e.activated),
                                    backgroundColor: '#198754',                                    
                                    borderColor: '#157348',
                                    borderWidth: 1,
                                },
                                {
                                    label: t('dashboard.renovated'),
                                    data: res.data.dashboards[0].map(e => e.renovated),
                                    backgroundColor: '#6eb446',                                    
                                    borderColor: '#3b7d3c',
                                    borderWidth: 1,
                                },
                                {
                                    label: t('dashboard.to_expire'),
                                    data: res.data.dashboards[0].map(e => e.to_expire),
                                    backgroundColor: '#ffc107',                                    
                                    borderColor: '#d9a406',
                                    borderWidth: 1,
                                },
                                {
                                    label: t('dashboard.expired'),
                                    data: res.data.dashboards[0].map(e => e.expired),
                                    backgroundColor: '#dc3545',                                    
                                    borderColor: '#bb2d3b',
                                    borderWidth: 1,
                                },
                                {
                                    label: t('dashboard.available'),
                                    data: res.data.dashboards[0].map(e => e.available),
                                    backgroundColor: '#0d6efd',                                    
                                    borderColor: '#0b60dc',
                                    borderWidth: 1,
                                }],
                            });
                            console.log(res.data);
                            setloading(true);
                        })
                        .catch(err => {
                            Swal.fire({
                                icon: 'error',
                                title: err?.response?.data?.response || err
                            });
                        });
        })();

        getProducts();
        if(tokenDecode?.roles.find(x => x.name === 'admin')){
            getAverage();
        }
        everySecond();

    }, [])
    

/***************************************************************************************************************************/

    const getProducts = async () => { 
        const headers = await getHeaders()
        await axios.get(`${url}dashboards/prices`, {headers})
                    .then(res => {
                        setProducts(res.data.message);
                        setPartner(res.data.partner);
                    }).catch(err =>{
                        console.log(err);
                    });
    }
/***************************************************************************************************************************/

    const everySecond = () => { 
        setInterval(() => {
            settoday(prevDateTime => prevDateTime.plus({seconds: 1}));
        }, 1000);
    }


/***************************************************************************************************************************/

    return (
        <>
            {
            dashboardOne !== null
            ?   <div className="row animate__animated animate__fadeIn">
                
                    <h2>{t('dashboard.today')}: {today.setLocale(i18n.language).toFormat('yyyy-LLLL-dd HH:mm:ss')}</h2>
                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-success">
                            <div className="card-body position-relative">
                                <h6 className="card-title fw-bold h4">{dashboardOne.activated} <span className="card-text fs-6 fw-normal">{t('dashboard.licenses_activated')}</span></h6>
                                <h6 className="card-title fw-bold h4">{dashboardOne.renovated} <span className="card-text fs-6 fw-normal">{t('dashboard.licenses_renovated')}</span></h6>
                                <h3 className="card-title fw-bold h1">{parseInt(dashboardOne.renovated) + parseInt(dashboardOne.activated) } <span className="card-text fs-4 fw-normal">Total</span></h3>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faCheckCircle} size='4x'/>
                                </div>
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.activated')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-warning">
                            <div className="card-body position-relative">
                                <h3 className="card-title fw-bold h1">{dashboardOne.to_expire}</h3>
                                <p className="card-text">{t('dashboard.licenses_to_expire')}</p>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faWarning} size='4x'/>
                                </div>							
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.to_expire')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-danger">
                            <div className="card-body position-relative">
                                <h3 className="card-title fw-bold h1">{dashboardOne.expired}</h3>
                                <p className="card-text">{t('dashboard.licenses_expired')}</p>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faXmarkCircle} size='4x'/>
                                </div>									
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.expired')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-primary">
                            <div className="card-body position-relative">
                                <h3 className="card-title fw-bold h1">{dashboardOne.available}</h3>
                                <p className="card-text">{t('dashboard.licenses_available')}</p>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faTableList} size='4x'/>
                                </div>										
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.available')}</span>
                            </div>
                        </div>
                    </div>

                </div>

            :   <section className='container-fluid border-bottom border-5 border-secondary '>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 border-1 justify-content-center'>
                    {
                        [1,2,3,4].map((e,i) => {
                            return <div className={`col animate__animated animate__fadeIn`} key={`loading-${i}`}>
                                <div className="card h-100 text-bg-light" aria-hidden="true">
                                    <div className="card-header">
                                        <p className="placeholder-glow">
                                            <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title placeholder-glow">
                                            <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        </h5>
                                        <p className="card-text placeholder-glow">
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        </p>
                                    </div>
                                    <div className="card-footer">
                                        <a href="#!" tabIndex="-1" className=" bg-secondary disabled placeholder col-6"></a>
                                    </div>
                                        
                                </div>
                            </div>                   
                        })
                    }
                </div>      
                </section>             
            }
            {
                loading &&
                    <div className='mb-3 d-flex justify-content-center' style={{ width: '100%', height: '300px' }}>
                        {/* <Chart type='line' data={data} options={options}/> */}
                        <Chart type='line' data={data} options={{
                                maintainAspectRatio: false,                                   
                                responsive: true,                                
                                plugins   : {
                                    legend: {
                                        position: 'top',
                                        
                                    },
                                    title: {
                                        display: true,
                                        text   : today.setLocale(i18n.language).toLocaleString({month: 'long'}),
                                    },                                                                        
                                },
                                elements: {
                                    point: {
                                        radius: 6, // Tamaño del radio de los puntos
                                        borderWidth: 2, // Ancho del borde de los puntos
                                    },
                                    line: {
                                        borderWidth: 2, // Ancho del borde de las líneas
                                    },
                                },
                            }}
                        />
                    </div>
            }
            {
                (dataSum !== null && tokenDecode?.roles.find(x => x.name === 'admin')) &&
                    <div className='mb-3 d-flex justify-content-center' style={{ width: '100%', height: '300px' }}>
                        <Bar data={dataSum} 
                            options={{
                                maintainAspectRatio: false,                                   
                                responsive: true,                                
                                plugins   : {
                                    legend: {
                                        position: 'top',                                
                                    },
                                    title: {
                                        display: true,
                                        text   : `Aplicaciones en uso`,
                                    },
                                    datalabels: {
                                        backgroundColor: function (context) {
                                            // return context.dataset.backgroundColor;
                                            return '#FFFFFF';
                                        },
                                        
                                        formatter: (val, context) => `${val}`,
                                        borderRadius: 15,
                                        borderWidth: 2,
                                        color: "black",
                                        font: {
                                            weight: "bold"
                                        },
                                        padding: 2
                                    },
                                },
                            }}
                        />
                    </div>
            }
            {                
                (dataAverage !== null && tokenDecode?.roles.find(x => x.name === 'admin')) &&
                    <div className="row border-top border-bottom py-2">
                        <p className='text-center text-bold'>Promedio de usuarios activos por mes</p>
                        {dataAverage.map((e, i) =>
                            <div key={`da-${i}`} className='mb-3 col-md-4' style={{ height: '300px' }}>
                                <Pie                                 
                                    data={{
                                        labels: ['Pro', 'Free'],
                                        datasets: [
                                            {
                                                label      : 'Promedio',
                                                data       : [e.avgIsPro, e.avgNoPro],
                                                borderColor: [
                                                    'rgba(25, 135, 84, 0.8)',
                                                    'rgba(220, 53, 69, 0.7)',
                                                ],
                                                backgroundColor: [
                                                    'rgba(25, 135, 84, 0.8)', 
                                                    'rgba(220, 53, 69, 0.7)'
                                                ],
                                                borderWidth: 1,
                                            },
                                        ]
                                    }} 
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive         : true,
                                        plugins            : {
                                            legend: {
                                                position: 'top',                                
                                            },
                                            title: {
                                                display: true,
                                                text   : `${e.label}`
                                            },
                                            datalabels: {
                                                backgroundColor: function (context) {
                                                    // return context.dataset.backgroundColor;
                                                    return '#FFFFFF';
                                                },
                                                formatter: (val, context) => {
                                                    const num = (Number(val) * 100) /
                                                    context.chart.data.datasets[context.datasetIndex].data.reduce(
                                                        (a, b) => Number(a) + Number(b),
                                                        0
                                                    )
                                                    const numFormat = num.toFixed(2)
                                                    const valFormat = parseFloat(val);
                                                    return `C: ${valFormat.toFixed(2)} \nP: ${ numFormat }%`
                                                },
                                                borderRadius: 25,
                                                borderWidth: 3,
                                                color: "black",
                                                font: {
                                                    weight: "bold"
                                                },
                                                padding: 6
                                            },
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
            }
            {
                (dataAverageByProduct !== null && tokenDecode?.roles.find(x => x.name === 'admin')) &&
                <div className="row border-top border-bottom py-2">
                    <p className='text-center text-bold'>Promedio de usuarios activos por aplicación</p>
                    {dataAverageByProduct.map((e, i) =>
                        <div key={`dab-${i}`} className='mb-3 col-md-4 border-end'>
                            <div className='' style={{ height: '300px' }}>
                                <Pie data={{
                                    // labels: ['Text2 Barcode', 'PDF Label Cut', 'SMP', 'Codaly'],
                                    labels: e.data.map(f => f.productName),
                                    datasets: [
                                        {
                                            data:  e.data.map(f => 
                                                (
                                                    f.productName === 'Text2 barcode' ||
                                                    f.productName === 'PDF Label Cut' ||
                                                    f.productName === 'SMP' ||
                                                    f.productName === 'Codaly'
                                                )
                                                ? f.avgIsPro
                                                : 0
                                                // : null
                                            ),
                                            backgroundColor: e.data.map(f => {
                                                switch (f.productName) {
                                                    case 'Text2 barcode':
                                                        return 'rgba(204, 1, 1, 0.8)'
                                                        break;
                                                    case 'PDF Label Cut':
                                                        return 'rgba(241, 140, 37, 0.8)'
                                                        break;
                                                    case 'SMP':
                                                        return 'rgba(106, 174, 237, 0.8)'
                                                        break;
                                                    case 'Codaly':
                                                        return 'rgba(33, 33, 33, 0.8)'
                                                        break;
                                                }
                                            }),
                                            borderWidth    : 1,
                                        },
                                    ]}} 
                                    options={{
                                        maintainAspectRatio: false,                                   
                                        responsive: true,   
                                        plugins   : {
                                            legend: {
                                                position: 'top',                                
                                            },
                                            title: {
                                                display: true,
                                                text   : `${e.label} PRO`,
                                            },
                                            datalabels: {
                                                backgroundColor: function (context) {
                                                // return context.dataset.backgroundColor;
                                                return '#FFFFFF';
                                                },
                                                formatter: (val, context) => {
                                                    const num = (Number(val) * 100) /
                                                    context.chart.data.datasets[context.datasetIndex].data.reduce(
                                                    (a, b) => Number(a) + Number(b),
                                                    0
                                                    )
                                                    const numFormat = num.toFixed(2)
                                                    const valFormat = parseFloat(val);
                                                    return `C: ${valFormat.toFixed(2)} \nP: ${ numFormat }%`
                                                },
                                                borderRadius: 25,
                                                borderWidth: 3,
                                                color: "black",
                                                font: {
                                                weight: "bold"
                                                },
                                                padding: 6
                                            },
                                            
                                        },
                                    }}
                                />
                            </div>
                            <div className='mt-5' style={{ height: '300px' }}>
                                <Pie data={{
                                    labels: e.data.map(f => f.productName+' Free'),
                                    datasets: [
                                        {
                                            data:  e.data.map(f => 
                                                (
                                                    f.productName === 'Text2 barcode' ||
                                                    f.productName === 'PDF Label Cut' ||
                                                    f.productName === 'SMP' ||
                                                    f.productName === 'Codaly'
                                                )
                                                ? f.avgNoPro
                                                : 0
                                                // : null
                                            ),
                                            backgroundColor: e.data.map(f => {
                                                switch (f.productName) {
                                                    case 'Text2 barcode':
                                                        return 'rgba(204, 1, 1, 0.8)'
                                                        break;
                                                    case 'PDF Label Cut':
                                                        return 'rgba(241, 140, 37, 0.8)'
                                                        break;
                                                    case 'SMP':
                                                        return 'rgba(106, 174, 237, 0.8)'
                                                        break;
                                                    case 'Codaly':
                                                        return 'rgba(33, 33, 33, 0.8)'
                                                        break;
                                                }
                                            }),                                       
                                            borderWidth    : 1,
                                        },
                                    ]}} 
                                    options={{
                                        maintainAspectRatio: false,                                   
                                        responsive: true,   
                                        plugins   : {
                                            legend: {
                                                position: 'top',                                
                                            },
                                            title: {
                                                display: true,
                                                text   : `${e.label} FREE`,
                                            },
                                            datalabels: {
                                                backgroundColor: function (context) {
                                                // return context.dataset.backgroundColor;
                                                return '#FFFFFF';
                                                },
                                                formatter: (val, context) => {
                                                    const num = (Number(val) * 100) /
                                                    context.chart.data.datasets[context.datasetIndex].data.reduce(
                                                    (a, b) => Number(a) + Number(b),
                                                    0
                                                    )
                                                    const numFormat = num.toFixed(2)
                                                    const valFormat = parseFloat(val);
                                                    return `C: ${valFormat.toFixed(2)} \nP: ${ numFormat }%`
                                                },
                                                borderRadius: 25,
                                                borderWidth: 3,
                                                color: "black",
                                                font: {
                                                weight: "bold"
                                                },
                                                padding: 6
                                            },
                                            
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    )}                                                                  
                </div>
            }
            {                
                (dataAverageByUsers !== null && tokenDecode?.roles.find(x => x.name === 'admin')) &&
                    <div className="row border-top border-bottom py-2">
                        <p className='text-center text-bold'>Cantidad de usuarios PRO / FREE</p>
                        {dataAverageByUsers.map((e, i) =>
                            <div key={`da-${i}`} className='mb-3 col-md-4' style={{ height: '300px' }}>
                                <Pie                                 
                                    data={{
                                        labels: ['Pro', 'Free'],
                                        datasets: [
                                            {
                                                label      : 'users',
                                                data       : [e.isPro, e.noPro],
                                                borderColor: [
                                                    'rgba(25, 135, 84, 0.8)',
                                                    'rgba(220, 53, 69, 0.7)',
                                                ],
                                                backgroundColor: [
                                                    'rgba(25, 135, 84, 0.8)', 
                                                    'rgba(220, 53, 69, 0.7)'
                                                ],
                                                borderWidth: 1,
                                            },
                                        ]
                                    }} 
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive         : true,
                                        plugins            : {
                                            legend: {
                                                position: 'top',                                
                                            },
                                            title: {
                                                display: true,
                                                text   : `${e.label}`
                                            },
                                            datalabels: {
                                                backgroundColor: function (context) {
                                                    // return context.dataset.backgroundColor;
                                                    return '#FFFFFF';
                                                },
                                                formatter: (val, context) => {
                                                    const num = (Number(val) * 100) /
                                                    context.chart.data.datasets[context.datasetIndex].data.reduce(
                                                        (a, b) => Number(a) + Number(b),
                                                        0
                                                    )
                                                    const numFormat = num.toFixed(2)
                                                    const valFormat = parseFloat(val);
                                                    return `C: ${valFormat.toFixed(2)} \nP: ${ numFormat }%`
                                                },
                                                borderRadius: 25,
                                                borderWidth: 3,
                                                color: "black",
                                                font: {
                                                    weight: "bold"
                                                },
                                                padding: 6
                                            },
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
            }
                <div className='card mb-5'>
                    <div className='card-header'>
                        <h3 className='text-center m-0'>Prices</h3>
                    </div>
                    <div className='card-body'>

                        <div className='row'>
                            <div className="col-md-12">
                                <div className='text-center'>
                                    {
                                        tokenDecode.prepay
                                        ? <p>User prepay</p>
                                        : <p>Usuario de Pospago</p>
                                    }
                                </div>
                            </div>
                        </div>

                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Precio</th>
                                    <th scope="col">Descuento /// = Precio Distribuidor</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                            {
                                products.length > 0 &&
                                products.map((e,i) =>{
                                    return <tr key={`product-${i}`}>
                                        <td>{e.name}</td>
                                        {
                                            partner === null &&
                                            <td>${e?.list_price_mx} mxn <b>--</b> ${e?.list_price} usd</td>
                                        }
                                        {
                                            partner !== null &&
                                            (partner?.country?.toLowerCase() === 'mexico' ||
                                             partner?.country?.toLowerCase() === 'méxico' ) 
                                            ?   <td>${e?.list_price_mx} mxn</td>
                                            :   <td>${e?.list_price} usd</td>
                                        }
                                        <TdPrices product={e} partner={partner}/>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
        </>
    )
}

export default AdminDashboard